import React from 'react'
import './Team.css'
import { Ksiadz01,Ksiadz02 } from '../../assets'

const teamList = [
  {
    img:Ksiadz01,
    name:"Jan Nowak",
    opis:" Lorem, ipsum dolor sit amet consectetur adipisicing elit."

  },
  {
    img:Ksiadz02,
    name:"Jarosław Wiśniewski",
    opis:" Lorem, ipsum dolor sit amet consectetur adipisicing elit."

  }
]

const Team = () => {
  return (
    <div className='team__kontener margin__wysokoscSekcji padding__wysokoscGora padding__wysokoscDol padding__szerokoscPrawy padding__szerokoscLewy'>
     <div className='team__head'>
    <h3 className='section-title'>Poznaj Naszych Duchownych</h3>  
     </div>  
     <div className='team__module-kontener'>
      {
        teamList.map(
          (data,index)=> {
            return (
              <div className='team__module' key={index}>
                <div className='team__module-imgKontener'>
                  <img src={data.img} alt={data.name} />
                </div>
                <div className='team__module-text'>
                  <h4>{data.name}</h4>
                  <p>{data.opis}</p>
                </div>
              </div>
            )
          }
        )
      }

     </div>
    </div>
   
  )
}

export default Team