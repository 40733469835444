import React from 'react'
import './Slogan.css'

const Slogan = () => {
  return (
    <div className='slogan__kontenr padding__szerokosKontenera margin__wysokoscSekcji'>
      <div className='slogan__slogan-text'>
        <h1>"Nic tak nie jest potrzebne człowiekowi jak Miłosierdzie Boże - owa miłość łaskawa, współczująca, wynosząca człowieka ponad jego słabość ku nieskończonym wyżynom Świętości Boga."</h1>
      </div>
      <div className='slogan__autor'>
        <p>Jan Paweł II</p>
        </div>
    </div>
  )
}

export default Slogan