import React from 'react'
import './Opinie.css'
import { Opinie01, Poeple01 } from '../../assets'

const Opinie = () => {
  return (
    <div className='opine__wrapper'>
      <div className='opinie__kontener padding__szerokosKontenera padding__wysokośćKontenera'>
       <div className='opinie__head'>
        <h3 className='section-title'>Wpisy Naszych Parafianów</h3>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur fuga expedita illo inventore esse consectetur sapiente quas iusto voluptates harum. Natus corrupti assumenda ea non similique illum delectus voluptas atque?</p>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur fuga expedita illo inventore esse consectetur sapiente quas iusto voluptates harum. Natus corrupti assumenda ea non similique illum delectus voluptas atque?</p>
       <button>Czytaj więcej</button>
       </div>
       <div className='opinie__komentarz'>
        <div className='opinie__komentarz-imgKontener'>
          <img src={Opinie01} alt="opinie" />
        </div>
        <div className='opinie__komentarz-wpisKontener'>
          <div className='opinie__komentarz-paragraph'>
           
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi assumenda quas animi! Quisquam placeat repellendus provident corporis. Nemo labore quia culpa vel pariatur autem dolor, inventore reiciendis ullam numquam deserunt.</p>
          </div>
          <div className='opinie__person'>
            <div className='opinie__person-img'>
            <img src={Poeple01} alt="" />
            </div>
            <div className='opinie__person-name'>
            <h4>Jan Kowalski</h4>
            </div>
            <div className='opinie__person-selector'>
              <span className='span__empty'></span>
              <span className='span__empty'><span className='span__active'></span></span>
              <span className='span__empty'></span>
            </div>
          </div>
        </div>
       </div>
      </div>
    </div>
  )
}

export default Opinie