import React from 'react'
import './Kontakt.css'

const Kontakt = () => {
  return (
    <div className='kontakt__kontener'>
     <div className='kontakt__sekcja padding__szerokosKontenera padding__wysokośćKontenera'>
      <div className='kontakt__head'>
      <h4 className='section-title'>Konsultacja</h4>
      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>
      </div>
      <div className='kontakt__fomularz-kontener'>
        <input type="text" placeholder='Imię' />
        <input type="text" placeholder='Nazwisko' />
        <input type="text" placeholder='E-mail' />
        <button>Wyslij</button>
     </div>
     </div>
   
     
    </div>
  )
}

export default Kontakt