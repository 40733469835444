import React from 'react'
import './Kazania.css'
import {Kazania01,Kazania02, Kazania03} from '../../assets'

const kazaniaList = [
  {
    img:Kazania01,
    kategoria:"kazania",
    title:"Słowo z Srebrnej Góry",
    data:"07 Września 2023"
  },
  {
    img:Kazania02,
    kategoria:"kazania",
    title:"Szanse jakie daje nam wiara",
    data:"07 Września 2023"
  },
  {
    img:Kazania03,
    kategoria:"kazania",
    title:"Mamy siłę",
    data:"07 Września 2023"
  }
]

const Kazania = () => {
  return (
    <div className='kazania__wrapper'>
      <div className='kazania__kontener padding__szerokosKontenera padding__wysokośćKontenera'>
      <div className='kazania_head'>
        <h1 className='section-title'>Liturgia Słowa</h1>
        <p className='text-paragraph'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore possimus mollitia laborum sunt quisquam hic ipsa nisi ipsam.</p>
      </div>
     <div className='kazania_sekcja-moduly'>
          {
            kazaniaList.map(
              (data,index) => {
                return (
                  <div className='kazania__modul' key={index}>
                    <div className='kazania__modul-imgKontener'>
                      <img src={data.img} alt={data.title} />
                    </div>
                    <div className='kazania__modul-text'>
                      <p><span>{data.kategoria}</span></p>
                      <h3>{data.title}</h3>
                      <span className='kazania__span-border'></span>
                      <p className='kazania__data'>{data.data}</p>
                    </div>
                  </div>
                )
              }
            )
          }

     </div>
     </div>
      </div>
  )
}

export default Kazania