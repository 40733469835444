import React, { useState } from "react";
import "./Header.css";
import { Nav } from "../../Components/";
import { Church } from "../../assets";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [menuMobile, setmenuMobile] = useState(false);

  return (
    <>
      <div className="header__nav-container_mobile padding__szerokosKontenera ">
        <div className="hamburgerMenu padding__szerokosKontenera">
          {!menuMobile ? (
            <button onClick={() => setmenuMobile(true)}>
              <AiOutlineMenu />
            </button>
          ) : (
            <button onClick={() => setmenuMobile(false)}>
              <AiOutlineClose />
            </button>
          )}
        </div>
      </div>
      {menuMobile && (
        <div className="menu__mobile">
          <Nav />
        </div>
      )}
      <div
        className="header__kontener"
        style={{ backgroundImage: `url(${Church})` }}>
        <div className="header__kontener-nakladka"></div>
        <div className="header__kontenerSekcji padding__szerokosKontenera">
          <div className="header__nav-kontener">
            <Nav />
          </div>
          <div className="header__slider-kontener">
            <div className="header__slider-headSection">
              <h4>Witaj w Naszym Kościele</h4>
              <h2>Dołącz do Wspólnoty Wiernych</h2>
            </div>
            <div className="header__slider-text">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptate optio maiores eius sint minima officiis id molestias
                quas laudantium cum, nostrum dicta esse aliquam porro, excepturi
                dolorem hic autem possimus.
              </p>
            </div>
            <div className="header__slider-nav">
              <button>Czytaj więcej...</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
