import React from 'react'
import './Footer.css'
import {TbBible} from 'react-icons/tb'

const Footer = () => {
  return (
    <div className='footer__kontener'>
      <div className='footer__sekcja padding__szerokosKontenera padding__wysokośćKontenera'>
        <div className='footer__head'>
          <h2 className='section-title'><TbBible/></h2>
          <h3 className='section-title'>Parafia Miłosierdzia Bożego</h3>
         </div>
     <div className='footer__menu'>
     <p><a href="#">Strona Główna</a></p>
      <p><a href="#">Parafia</a></p>
      <p><a href="#">Kazania</a></p>
      <p><a href="#">Intencje</a></p>
      <p><a href="#">Księża</a></p>
      <p><a href="#">Kontakt</a></p>
     </div>
     <div className='footer__kontakt'>
      <p>Zadzwoń do Nas</p>
      <h4>+48 000 - 000 - 000</h4>
     </div>
     <div className='footer__adres'>
      <p>ul. Nazwa Ulicy 15</p>
      <p>00 - 000 Miejscowość</p>
      <p>Copyright © 2023 All Rights Reserved.</p>
     </div>
      </div>
      </div>
  )
}

export default Footer