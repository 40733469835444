import {
  About,
  Footer,
  Header,
  Kazania,
  Kontakt,
  Oferta,
  Opinie,
  Slogan,
  Team
} from './Containers/'

function App () {
  return (
    <div className='App'>
      <Header />
      <Slogan />
      <About />
      <Kazania />
      <Oferta />
      <Opinie />
      <Team />
      <Kontakt />
      <Footer />
    </div>
  )
}

export default App
