import React from "react";
import "./Nav.css";
import { TbBible } from "react-icons/tb";

const Nav = () => {
  return (
    <div className="nav__kontener">
      <div className="nav__logo">
        <div className="nav__logo-container">
          <p>
            <span>
              <TbBible />
            </span>
          </p>
        </div>
        <div className="nav__logo-text">
          <p> Parafia Miłosierdzia Bożego</p>
        </div>
      </div>
      <div className="nav__menu">
        <p>
          <a href="#">Strona Główna</a>
        </p>
        <p>
          <a href="#">Parafia</a>
        </p>
        <p>
          <a href="#">Kazania</a>
        </p>
        <p>
          <a href="#">Intencje</a>
        </p>
        <p>
          <a href="#">Księża</a>
        </p>
        <p>
          <a href="#">Kontakt</a>
        </p>
      </div>
    </div>
  );
};

export default Nav;
