import React from 'react'
import "./About.css"
import { ChurchAbout01 } from '../../assets'

const About = () => {
  return (
    <div className='about__kontener'>
      <div className='about__zdjecie'>
        <img src={ChurchAbout01} alt="" />
      </div>
      <div className='about__text'>
        <div className='about__text-kontener'>
          <h2 className='section-title'>O Naszym Kościele</h2>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis, quis atque, at suscipit dolor a iusto quia magnam vero nulla distinctio veritatis quod non, nemo architecto dignissimos neque commodi sequi.</p>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis, quis atque, at suscipit dolor a iusto quia magnam vero nulla distinctio veritatis quod non, nemo architecto dignissimos neque commodi sequi.</p>
          <button>Czytaj więcej...</button>
        </div>
      </div>
    </div>
  )
}

export default About