import React from 'react'
import './Oferta.css'
import { Offer01,Offer02,Offer03,Offer04 } from '../../assets'

const ofertaList = [
  {
    img:Offer01,
    subtitle:"Poznawaj z nami świat biblii",
    title:"Czytanie Biblii"
  },
  {
    img:Offer02,
    subtitle:"Pieczęć jedności dwojga",
    title:"Wesele"
  },
  {
    img:Offer03,
    subtitle:"Pierwsze przyjęcie Eucharystii",
    title:"Pierwsza Komunia"
  },
  ,
  {
    img:Offer04,
    subtitle:"Upamiętnienie przybycia do Betlejem",
    title:"Pasterka"
  }
]

const Oferta = () => {
  return (
    <div className='oferta__wrapper'>
      <div className='oferta__kontener padding__szerokoscPrawy padding__szerokoscLewy padding__wysokoscGora '>
      <div className='oferta__head'>
        <h4 className='section-title'>Nabożeństwa</h4>
      </div>
      <div className='oferta__nabozenstwa-kontener padding__wysokoscGora '>
      {
        ofertaList.map(
          (data,index) => {
            return (
              <div className='oferta__nabozensta-modul' key={index}>
                <div className='oferta__nabozensta-modul_imgKontener'>
                  <img src={data.img} alt={data.title} />
                </div>
                <div className='oferta__nabozenstwa-modul_textKontener'>
                  <h3>{data.subtitle}</h3>
                  <h2>{data.title}</h2>
                  <span></span>
                </div>
              </div>
            )
          }
        )
      }

      </div>
    </div>
    </div>
  )
}

export default Oferta